<template>
  <div class="avatars">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <div class="avatarList">
        <div
          class="avatarItem"
          v-for="item in avatarList"
          :key="item.id"
          @click="changeActive(item)"
        >
          <ImgDecypt
            class="avatarImg"
            :src="item.resource"
            :key="item.resource"
            round
          />
          <svg-icon
            class="selectActive"
            iconClass="selectActive"
            v-if="selectAcatar == item.resource"
          />
        </div>
      </div>
    </PullRefresh>
  </div>
</template>

<script>
import { queryAvatarList } from "@/api/user";
import { upload } from "@/api/app";
import PullRefresh from "@/components/PullRefresh";
import ImgDecypt from "@/components/ImgDecypt";
import { modifyUserInfo } from "@/api/user";
import { Toast } from "vant";
export default {
  components: {
    PullRefresh,
    ImgDecypt,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 30,
      avatarList: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      selectAcatar: "",
      userInfo: {},
      type: "avatar",
    };
  },
  created() {
    this.userInfo = this.$store.getters.userInfo;
    this.selectAcatar = this.$store.getters.userInfo.portrait;
    this.queryList();
  },
  methods: {
    // 查询头像列表
    async queryList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        type: "avatar",
      };
      try {
        let res = await this.$Api(queryAvatarList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          // this.avatarList = res.data.list;
          if (type === "refresh") {
            this.avatarList = list;
          } else {
            this.avatarList = this.avatarList.concat(list);
          }
          if (this.pageNumber == 1 && this.avatarList.length == 0) {
            this.isNoData = true;
          }
          if (this.pageSize > list.length) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.queryList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.queryList("refresh");
    },
    // 修改头像
    changeActive(item) {
      this.selectAcatar = item.resource;
      this.$emit("changeAvatar", this.selectAcatar);
    },
    showToast() {
      Toast("您还不是VIP，无法上传头像");
    },
  },
};
</script>

<style lang="scss" scoped>
.avatars {
  height: calc(100vh - 269px);
  // .main {
  //   height: calc(100% - 44px);
  //   // overflow-y: auto;
  //   // -webkit-overflow-scrolling: touch;
  // }

  // /deep/ .refreshing_warp {
  //     height: calc(100% - 170px);
  // }
  /deep/ .van-pull-refresh {
    height: 100%;
  }

  /deep/ .van-list__finished-text {
    display: none;
  }

  .avatarList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 20px;
    padding: 0px 38px 0px;
    box-sizing: border-box;

    .avatarItem {
      width: 60px;
      height: 60px;
      position: relative;

      .avatarImg {
        width: 100%;
        height: 100%;

        /deep/ .vanImage {
          background: #00000000 !important;
        }

        /deep/ .van-image__error {
          background: #00000000 !important;
        }
      }

      .selectActive {
        width: 22px;
        height: 22px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  .confirmBtn {
    width: 284px;
    height: 44px;
    border-radius: 44px;
    line-height: 44px;
    font-size: 18px;
    // margin: 30px auto;
    text-align: center;
    background: #94d6da;
    color: #fff;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
