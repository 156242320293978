<template>
  <div class="editAvatar">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">选择头像</div>
      <div class="rightBtn" @click="modifyAvatar">保存</div>
    </div>
    <div class="tabBox">
      <div
        class="tabItem"
        :class="{ activeTab: type == 'avatar' }"
        @click="selectTab('avatar')"
      >
        头像
      </div>
      <div
        class="tabItem"
        :class="{ activeTab: type == 'photoFrame' }"
        @click="selectTab('photoFrame')"
      >
        头像框
      </div>
    </div>
    <div class="avatarBox">
      <ImgDecypt
        class="avatarImg"
        :src="selectAcatar"
        round
        :key="selectAcatar + 'avatar'"
      />
      <ImgDecypt
        class="avatarFrame"
        :src="avatarFrame"
        round
        :key="avatarFrame + 'avatarFrame'"
        v-if="avatarFrame"
      />
      <!-- <div class="maskBox">
        <svg-icon class="camera" iconClass="camera" />
      </div> -->
      <!-- <input
        class="file"
        type="file"
        @change="chooseImg"
        @click.stop
        v-if="userInfo.isVip"
      />
      <div class="file" @click="showVipPop" v-else></div> -->
    </div>
    <!-- <div class="uploadTip">点击头像上传头像</div> -->
    <AvatarList
      @changeAvatar="changeActive"
      v-if="type == 'avatar'"
      @showVipPop="showVipPop"
    />
    <AvatarFrameList
      @changeFrame="changeFrame"
      :avatar="selectAcatar"
      @showVipPop="showVipPop"
      v-else
    />
    <van-popup close-icon="tipPop" v-model="showTip">
      <div class="tipContent">
        <div class="popTitle">温馨提示</div>
        <div class="popContent">您还不是会员 无法继续</div>
        <div class="popTip">开通会员 即可解锁使用</div>
        <div class="btnBox">
          <div class="btn" @click="jumpPage('/sharePromotion')">邀请好友</div>
          <div class="btn" @click="jumpPage('/rechargePage?t=vip')">
            开通会员
          </div>
        </div>
      </div>
    </van-popup>

    <!-- <div class="confirmBtn" @click="modifyAvatar">确定</div> -->
  </div>
</template>

<script>
import { queryAvatarList } from "@/api/user";
import { upload } from "@/api/app";
import PullRefresh from "@/components/PullRefresh";
import ImgDecypt from "@/components/ImgDecypt";
import AvatarList from "./components/AvatarList.vue";
import AvatarFrameList from "./components/AvatarFrameList.vue";
import { modifyUserInfo } from "@/api/user";
import { Toast } from "vant";
export default {
  components: {
    // PullRefresh,
    ImgDecypt,
    AvatarList,
    AvatarFrameList,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 30,
      avatarList: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      selectAcatar: "",
      avatarFrame: "",
      userInfo: {},
      type: "avatar",
      showTip: false,
    };
  },
  created() {
    this.userInfo = this.$store.getters.userInfo;
    this.selectAcatar = this.$store.getters.userInfo.portrait;
    this.avatarFrame = this.$store.getters.userInfo.portraitFrame;
    console.log(this.userInfo);
    this.queryList();
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    selectTab(type) {
      this.type = type;
    },
    async chooseImg(e) {
      let params = new FormData();
      params.append("upload", e.target.files[0]);
      let ret = await this.$Api(upload, params);
      if (ret.code == 200) {
        Toast("上传成功");
        this.selectAcatar = ret.data.coverImg;
      } else {
        Toast(ret.tip || "上传失败");
      }
    },
    // 查询头像列表
    async queryList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        type: "avatar",
      };
      try {
        let res = await this.$Api(queryAvatarList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          // this.avatarList = res.data.list;
          if (type === "refresh") {
            this.avatarList = list;
          } else {
            this.avatarList = this.avatarList.concat(list);
          }
          if (this.pageNumber == 1 && this.avatarList.length == 0) {
            this.isNoData = true;
          }
          if (this.pageSize > list.length) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.queryList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.queryList("refresh");
    },
    // 修改头像
    changeActive(avatar) {
      this.selectAcatar = avatar;
    },
    changeFrame(frame) {
      this.avatarFrame = frame;
    },
    showVipPop() {
      this.showTip = true;
    },
    showToast() {
      Toast("您还不是VIP，无法上传头像");
    },
    // 修改头像
    async modifyAvatar() {
      let req = {
        portrait: this.selectAcatar,
        portraitFrame: this.avatarFrame,
      };
      let ret = await this.$Api(modifyUserInfo, req);
      if (ret && ret.code == 200) {
        Toast("修改成功");
        this.$router.go(-1);
      } else {
        Toast(ret.tip || "编辑失败，请稍后再试");
      }
    },
    jumpPage(path) {
      if (path) {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editAvatar {
  height: 100%;
  color: #000;
  background: #f5f5f5;
  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
      margin-right: 7px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 18px;
    }

    .rightBtn {
      // width: 17px;
      color: #333;
      font-size: 12px;
      // height: 17px;
    }
  }
  .tabBox {
    width: 342px;
    height: 33px;
    padding: 3.5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: #f0f0f3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 17px auto 0;
    .tabItem {
      width: 162px;
      height: 26px;
      font-size: 12px;
      font-weight: 900;
      color: #555;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .activeTab {
      width: 157.6px;
      height: 21.6px;
      border-radius: 8px;
      border: 2.2px solid rgba(0, 0, 0, 0.05);
      background: #f0f0f3;
    }
  }
  .avatarBox {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin: 25px auto 79px;
    // overflow: hidden;
    position: relative;
    .avatarFrame {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 130px;
      height: 130px;
    }
    .maskBox {
      width: 100%;
      height: 24px;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .camera {
        width: 16px;
        height: 16px;
      }
    }
    .file {
      position: absolute;
      width: 100%;
      height: 100%;
      // background: #000;
      z-index: 100;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
  .uploadTip {
    font-size: 15px;
    color: #999;
    text-align: center;
    margin-bottom: 30px;
  }
  .main {
    height: calc(100% - 44px);
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
  }

  // /deep/ .refreshing_warp {
  //     height: calc(100% - 170px);
  // }
  /deep/ .van-pull-refresh {
    height: 100%;
  }

  /deep/ .van-list__finished-text {
    display: none;
  }

  .avatarList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 20px;
    padding: 0px 38px 0px;
    box-sizing: border-box;

    .avatarItem {
      width: 60px;
      height: 60px;
      position: relative;

      .avatarImg {
        width: 100%;
        height: 100%;

        /deep/ .vanImage {
          background: #00000000 !important;
        }

        /deep/ .van-image__error {
          background: #00000000 !important;
        }
      }

      .selectActive {
        width: 22px;
        height: 22px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  .confirmBtn {
    width: 284px;
    height: 44px;
    border-radius: 44px;
    line-height: 44px;
    font-size: 18px;
    // margin: 30px auto;
    text-align: center;
    background: #94d6da;
    color: #fff;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.tipContent {
  width: 300px;
  height: 252px;
  border-radius: 10px;
  background: #fff;
  .popTitle {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid rgba(33, 33, 33, 0.1);
  }
  .popContent {
    color: #333;
    font-family: "PingFang SC";
    font-size: 20px;
    text-align: center;
    margin: 24px 0 18px;
  }
  .popTip {
    color: #333;
    font-family: "PingFang SC";
    font-size: 14px;
    text-align: center;
    margin-bottom: 33px;
  }
  .btnBox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;
    box-sizing: border-box;
    .btn {
      width: 124px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      color: #b09fd9;
      background: #fff;
      box-shadow: -5px -5px 20px 0px #fff,
        5px 5px 20px 0px rgba(174, 174, 192, 0.5);
      margin-right: 8px;
      font-family: "Dream Han Sans TC";
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
    }
    .btn:last-child {
      margin-right: 0;
    }
  }
}
/deep/ .van-popup {
  background: rgba(0, 0, 0, 0);
}
</style>
