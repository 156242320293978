<template>
  <div class="avatarFrameList">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <div class="list">
        <div
          class="avatarItem"
          v-for="item in avatarList"
          :key="item.id"
          @click="changeActive(item)"
        >
          <ImgDecypt class="avatarImg" :src="item.url" :key="item.url" round />
          <div class="label" :class="handelLabel(item.photoType)"></div>
          <svg-icon
            class="selectActive"
            iconClass="selectActive"
            v-if="selectAcatar == item.url"
          />
        </div>
      </div>
    </PullRefresh>
    <van-popup close-icon="tipPop" v-model="showGoldPop">
      <div class="tipContent">
        <div class="popTitle">金豆解锁</div>
        <div class="popContent">
          您只需花费 <span>{{ frameInfo.price }}</span> 金豆
        </div>
        <div class="popTip">即可解锁使用</div>
        <div class="btnBox">
          <div class="btn" @click="showGoldPop = false">稍后再说</div>
          <div class="btn" @click="buyFrame">立即支付</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { queryAvatarFrame, buyAvatarFrame } from "@/api/user";
import { upload } from "@/api/app";
import PullRefresh from "@/components/PullRefresh";
import ImgDecypt from "@/components/ImgDecypt";
import { modifyUserInfo } from "@/api/user";
import { Toast } from "vant";
export default {
  components: {
    PullRefresh,
    ImgDecypt,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 30,
      avatarList: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      selectAcatar: "",
      userInfo: {},
      type: "avatar",
      showGoldPop: false,
      frameInfo: {},
    };
  },
  created() {
    this.userInfo = this.$store.getters.userInfo;
    this.selectAcatar = this.$store.getters.userInfo.portrait;
    this.queryList();
  },
  methods: {
    // 查询头像列表
    async queryList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(queryAvatarFrame, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          // this.avatarList = res.data.list;
          if (type === "refresh") {
            this.avatarList = list;
          } else {
            this.avatarList = this.avatarList.concat(list);
          }
          if (this.pageNumber == 1 && this.avatarList.length == 0) {
            this.isNoData = true;
          }
          if (this.pageSize > list.length) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.queryList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.queryList("refresh");
    },
    async buyFrame() {
      try {
        let req = {
          id: this.frameInfo.id,
        };
        let res = await this.$Api(buyAvatarFrame, req);
        if (res.code === 200) {
          this.selectAcatar = this.frameInfo.url;
          this.$emit("changeFrame", this.selectAcatar);
          this.showGoldPop = false;
          this.pageNumber = 1;
          this.finished = false;
          this.loading = true;
          this.avatarList = [];
          this.queryList("refresh");
          Toast("解锁成功");
        } else {
          Toast(res.tip || "解锁失败，请稍后再试");
        }
      } catch (error) {
        console.log(error);
        Toast("解锁失败，请稍后再试");
      }
    },
    // 修改头像
    changeActive(item) {
      if (item.photoType == 1 && item.price > 0 && !item.isBuy) {
        this.frameInfo = item;
        this.showGoldPop = true;
      } else if (!this.userInfo.isVip && item.photoType == 3) {
        this.$emit("showVipPop");
      } else {
        this.selectAcatar = item.url;
        this.$emit("changeFrame", this.selectAcatar);
      }
    },
    handelLabel(permission) {
      if (permission == 1) {
        return "goldLabelIcon";
      } else if (permission == 0) {
        return "freeLabelIcon";
      } else if (permission == 3) {
        return "vipLabelIcon";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.avatarFrameList {
  height: calc(100vh - 269px);
  // .main {
  //   height: calc(100% - 44px);
  //   // overflow-y: auto;
  //   // -webkit-overflow-scrolling: touch;
  // }

  // /deep/ .refreshing_warp {
  //     height: calc(100% - 170px);
  // }
  /deep/ .van-pull-refresh {
    height: 100%;
  }

  /deep/ .van-list__finished-text {
    display: none;
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 20px;
    padding: 0px 10px 0px;
    box-sizing: border-box;

    .avatarItem {
      width: 75px;
      height: 75px;
      position: relative;
      // background: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      .label {
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 20px;
      }

      .avatarImg {
        width: 100%;
        height: 100%;

        /deep/ .vanImage {
          background: #00000000 !important;
        }

        /deep/ .van-image__error {
          background: #00000000 !important;
        }
      }

      .selectActive {
        width: 22px;
        height: 22px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  .goldLabelIcon {
    background: url("../../../../../assets/png/goldLabelIcon.png");
    background-size: 100% 100%;
  }
  .freeLabelIcon {
    background: url("../../../../../assets/png/freeLabelIcon.png");
    background-size: 100% 100%;
  }
  .vipLabelIcon {
    background: url("../../../../../assets/png/vipLabelIcon.png");
    background-size: 100% 100%;
  }
}
.tipContent {
  width: 300px;
  height: 252px;
  border-radius: 10px;
  background: #fff;
  .popTitle {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid rgba(33, 33, 33, 0.1);
  }
  .popContent {
    color: #333;
    font-family: "PingFang SC";
    font-size: 20px;
    text-align: center;
    margin: 24px 0 18px;
    span {
      color: #fc4162;
    }
  }
  .popTip {
    color: #333;
    font-family: "PingFang SC";
    font-size: 14px;
    text-align: center;
    margin-bottom: 33px;
  }
  .btnBox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;
    box-sizing: border-box;
    .btn {
      width: 124px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      color: #b09fd9;
      background: #fff;
      box-shadow: -5px -5px 20px 0px #fff,
        5px 5px 20px 0px rgba(174, 174, 192, 0.5);
      margin-right: 8px;
      font-family: "Dream Han Sans TC";
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
    }
    .btn:last-child {
      margin-right: 0;
    }
  }
}
/deep/ .van-popup {
  background: rgba(0, 0, 0, 0);
}
</style>
